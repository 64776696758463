/**
 *  ・クラス名
 *    マスタ取得モデル
 *  ・概要
 *    マスタを取得する関連処理を記述
 *  ・更新履歴
 */
import hexabaseState from '@/store/hexabase';
import BaseModel from '../baseModel';

/* eslint-disable @typescript-eslint/camelcase */
export interface Payload {
  conditions?: {
    id: string;
    search_value: Array<string>;
  }[];
  sort_fields?: [
    {
      id: string;
      order: 'desc' | 'asc';
    }
  ];
  per_page?: number;
  page?: number;
  use_display_id?: boolean;
}

export default class Employee extends BaseModel {
  // マスターDB名
  private dbName = 'm_employee';

  payload?: Payload;

  constructor(payload?: Payload) {
    super(payload);
    const defaultPayload = {
      conditions: [],
      per_page: 0,
      page: 1,
      use_display_id: true
    };
    this.payload = payload ? payload : defaultPayload;
  }

  async init() {
    // 検索条件payloadを使用して、APIから値を取得、各モデル値にセットする
    return await this.getItemList(hexabaseState.applicationId, this.dbName, this.payload);
  }

  async getData() {
    return await this.init();
  }

  /**
   * マスタデータをフロント（マスタコントロール）で
   * 使用可能な形に整形して返します
   */
  async getFormatData() {
    return await this.getData().then(data => this.modifyMasterData(data, 'id', this.dbName));
  }
}
