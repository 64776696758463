/**
 *  ・クラス名
 *    マスターメンテナンスモデル
 *  ・概要
 *    マスターメンテナンス画面構成に必要な処理を記述
 *  ・更新履歴
 *    2021/04/20 新規作成
 */
import BaseModel from './baseModel';
import Employee from '@/models/data/employee';
import Department from '@/models/data/department';
import {
  StatusActions,
  ActionFieldSetting,
  ActionButtons,
  FieldChildrenProp,
  FieldsInfo,
  GetInputFieldsActions
} from '@/services/hexabase/types';
import { MemberList } from '@/constants/setting/types';
import { Employeefields } from '@/constants/setting/employee';

export default class SettingModel extends BaseModel {
  private employee = new Employee();
  private department = new Department();

  public process = 'setting';

  public employeeMatchKey = 'employee_id';

  private masterData: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } } = {};

  private columns = [
    { value: 'id', text: '社員コード', width: '15%' },
    { value: 'name', text: '社員名', width: '25%' },
    { value: 'email', text: 'メールアドレス', width: '30%' },
    { value: 'hexa_email', text: 'HexaBaseメールアドレス', width: '30%' }
  ];

  constructor() {
    super();
  }

  /**
   * 各種入力フォームで使用する各マスタ情報を取得
   * プロパティーに格納します
   * @param none
   * @returns void
   */
  public async loadMasterData() {
    const mData: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } } = {};
    [mData['m_employee'], mData['m_department']] = await Promise.all([
      this.employee.getFormatData(),
      this.department.getFormatData()
    ]);

    mData['m_employee'].columns = this.columns;

    this.setMaster(mData);
  }

  /**
   * マスタ情報を取得します
   * @returns {array} - マスタ情報配列
   */
  public getMaster() {
    return this.masterData;
  }

  /**
   * マスタ情報を取得します
   * @returns {array} - マスタ情報配列
   */
  private setMaster(data: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } }) {
    this.masterData = data;
  }

  /**
   * HexaBaseユーザー一覧を取得します
   * @returns {Promise<MemberList[]>}
   */
  public async getDbMembers(): Promise<MemberList[]> {
    const getTopGroupData = await this.getGroup();
    const membersList = await this.getUsersInGroup(getTopGroupData.group.g_id, true);
    return membersList.members;
  }

  /**
   * 新規作成画面・詳細画面の入力要素を取得整形します
   * @param appId {string} - アプリケ―ションID
   * @param dbId {string} - データストアID
   * @param master {[k: string]: { [k: string]: Array<{ [k: string]: string }> }} - マスターデータ
   * @param formDefine {{[k: string]: any}[]} - フォーム定義
   * @param currentItems {FieldsInfo[]} - 既存アイテムデータ
   * @param inputFields {GetInputFieldsActons} - DBフィールド情報
   * @param filterDataType {string} - filterをかけたいDataType
   * @returns
   */
  public async getField(
    appId: string,
    dbId: string,
    master: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } },
    formDefine: { [k: string]: any }[],
    currentItems?: FieldsInfo[],
    inputFields?: GetInputFieldsActions,
    filterDataType = 'status'
  ): Promise<FieldChildrenProp[]> {
    const data = await this.makeFieldsList(appId, dbId, currentItems).then(data => {
      return this.addOptionalFormField(
        data.filter(v => v.dataType !== filterDataType),
        this.deepCopy(formDefine)
      )
        .map(field => {
          return inputFields !== undefined ? this.inputRestriction(field, inputFields) : field;
        })
        .map(field => this.addOptionalMasterData(field, this.masterData));
    });

    return data;
  }

  /**
   * 特定のフィールドにデータをセットします
   * @param fieldsData {FieldChildrenProp[]} - 入力要素
   * @param membersData {MemberList[]} - HexaBaseユーザーリスト
   * @returns FieldChildrenProp[] - フィールド情報
   */
  public setFieldData(
    fieldsData: FieldChildrenProp[],
    membersData: MemberList[]
  ): FieldChildrenProp[] {
    const findUidIndex = fieldsData.findIndex(
      field => field.display_id === Employeefields.userJoin.displayId
    );
    if (findUidIndex > -1) {
      fieldsData[findUidIndex].props!.selectData.options = membersData.map(user => {
        return { text: `${user.email} (${user.username})`, value: user.u_id };
      });
    }

    const findActiveIndex = fieldsData.findIndex(
      field => field.display_id === Employeefields.activeAccount.displayId
    );
    if (findActiveIndex > -1) {
      const inactive = fieldsData[findActiveIndex].props!.selectData.options.find(
        (option: { [k: string]: string }) =>
          option.display_id === Employeefields.activeAccount.value.inactive
      );
      if (inactive !== undefined) {
        if (
          fieldsData[findActiveIndex].props!.value === undefined ||
          fieldsData[findActiveIndex].props!.value === null ||
          fieldsData[findActiveIndex].props!.value.length === 0
        ) {
          fieldsData[findActiveIndex].props!.value = inactive.value;
        }
      }
    }

    return fieldsData;
  }

  public searchUpdateLocalData(
    modifyDataList: { [k: string]: any },
    masterDataList: { [k: string]: string }[]
  ) {
    const findIndex = masterDataList.findIndex(item => item.i_id === modifyDataList.i_id);
    if (findIndex !== undefined) {
      return {
        index: findIndex,
        data: {
          ...masterDataList[findIndex],
          ...{
            rev_no: modifyDataList.rev_no,
            u_id: modifyDataList.u_id
          }
        }
      };
    }
    return { index: -1, item: null };
  }

  /**
   * 指定アクションにてフィールド情報を書き換えます
   * 詳細画面にてアクション切り替え時使用
   * @param dbId {string} - データストアID
   * @param data {FieldChildrenProp[]} - フィールド情報
   * @param execAction {ActionButtons} - アクション情報
   * @param rules {{[k: string]: any}[]} - 入力ルール
   * @param masterList {[k: string]: string}[] - マスターデータ
   * @returns
   */
  async modifyFields(
    dbId: string,
    data: FieldChildrenProp[],
    execAction: ActionButtons,
    rules?: { [k: string]: any }[],
    masterList?: { [k: string]: string }[]
  ): Promise<FieldChildrenProp[]> {
    const inputFields = await this.getFieldsByActionId(dbId, execAction.action_id);
    data = this._addInputRules(
      data as Array<FieldChildrenProp>,
      inputFields.action_field_settings,
      rules,
      masterList
    );
    return this.modifyInputAllow(data, inputFields.action_field_settings) as FieldChildrenProp[];
  }
  // modifyFieldsに付随するルールの付与
  _addInputRules(
    fields: Array<FieldChildrenProp>,
    fieldSetting: { [k: string]: ActionFieldSetting },
    rules?: { [k: string]: any }[],
    masterList?: { [k: string]: string }[]
  ) {
    return fields.map(field => {
      if (field.props === undefined) return field;

      const ruleArray = [];

      // Hexa側設定必須項目
      if (fieldSetting[field.field_id] !== undefined) {
        // 必須項目確認
        if (fieldSetting[field.field_id].mandatory) {
          const requiredName = field.component === 'SelectArea' ? 'selectRequired' : 'required';
          ruleArray.push(requiredName);
        }
      }

      // アプリ側設定項目
      if (rules !== undefined) {
        const row = rules.find(val => val.id === field.display_id);
        if (row !== undefined) {
          ruleArray.push(row.rule);
        }
      }

      if (field.props!['rules'] === undefined) {
        field.props!['rules'] = '';
      }
      if (ruleArray.length > 0) field.props!['rules'] = ruleArray.join('|');

      if (field.props!.rules.length !== 0) {
        // 特殊なルール設定を下記に記述

        // フィールド内でユニークかどうか
        if (field.props!.rules.includes('is_uniq_account')) {
          const ruleArray = field.props!.rules.split('|');
          const findIndex = ruleArray.findIndex((v: string) => v === 'is_uniq_account');

          let excuteId = '';
          const target = fields.find(val => val.display_id === field.display_id);
          if (target !== undefined) {
            excuteId = target.props!.value;
          }

          if (masterList !== undefined) {
            const ids =
              excuteId !== undefined && excuteId !== null && excuteId.length !== 0
                ? masterList
                    .map(item => {
                      return item[field.display_id] !== excuteId ? item[field.display_id] : null;
                    })
                    .filter(v => v)
                : masterList.map(item => item[field.display_id]);

            ruleArray[findIndex] = `is_uniq_account:${ids.join(',')}`;
            field.props!.rules = ruleArray.join('|');
          }
        }
      }

      return field;
    });
  }
  // modifyFieldsに付随する入力制限解除処理
  modifyInputAllow(
    fields: Array<FieldChildrenProp>,
    fieldSetting: { [k: string]: ActionFieldSetting }
  ) {
    return fields.map(field => {
      if (fieldSetting[field.field_id] !== undefined) {
        const row = fieldSetting[field.field_id];
        if (row.update) {
          field.props! = { ...field.props!, ...{ isDisabled: false, isReadonly: false } };
        }
        if (field.dataType === 'dslookup') {
          field.props!.isBtnVisible = true;
          field.props!.isLeftSideInputReadOnly = false;
        }
        if (field.dataType === 'autonum') {
          field.props!.isDisabled = true;
          field.props!.isReadonly = true;
        }
        field = {
          ...field,
          ...{
            show: row.show,
            update: row.update,
            mandatory: row.mandatory
          }
        };
      }

      if (field.display_id === Employeefields.activeAccount.displayId) {
        const uIdField = fields.find(f => f.display_id === Employeefields.userJoin.displayId);
        if (
          uIdField !== undefined &&
          (uIdField.props!.value === undefined ||
            uIdField.props!.value === null ||
            uIdField.props!.value.length === 0)
        ) {
          field.props! = { ...field.props!, ...{ isDisabled: true, isReadonly: true } };
        }
      }
      return field;
    });
  }

  /**
   * 詳細画面メールアドレスサジェスト処理
   * @param fields {FieldChildrenProp[]} - フィールド情報
   * @param hexaMembers {MemberList[]} - HexaBaseユーザー情報
   * @returns {index: number, value: string} | null
   */
  detailPageSuggest(
    fields: FieldChildrenProp[],
    hexaMembers: MemberList[]
  ): { index: number; value: string } | null {
    let email = '';

    fields.find(field => {
      if (field.display_id === 'email') {
        email = field.props!.value;
      }
      return null;
    });

    if (email === undefined || email.length === 0) return null;

    const findHexaSelectIndex = fields.findIndex(
      field => field.display_id === Employeefields.userJoin.displayId
    );
    if (findHexaSelectIndex > -1) {
      const matchUser = hexaMembers.find(member => member.email === email);
      const uId = matchUser !== undefined && findHexaSelectIndex > -1 ? matchUser.u_id : '';
      return { index: findHexaSelectIndex, value: uId };
    }

    return null;
  }

  /**
   * ステータスボタンリストの加工 （wrapper）
   * 各ボタンの活性・非活性等を定義ファイルと付け合わせる
   * @param {string} userId - 社員マスタ内のログインユーザーのアイテムID
   * @param {Array<StatusActions>} statusActionList - ステータスアクションリスト
   * @param {string} part - ドメイン名
   * @param {Array<FieldsInfo>} itemData - アイテム詳細データ
   * @param {string} buttonType - 押下されたボタンアクション
   * @returns {Array} ステータスリスト
   */
  public statusButtonList(
    statusActionList: Array<StatusActions>,
    itemData?: Array<FieldsInfo>,
    buttonType?: string
  ): Array<StatusActions> {
    // 押下ボタンのタイプがある場合押下アクション以外は非活性
    if (statusActionList.length > 0 && this.isNullOrUndefined(buttonType)) {
      return statusActionList.map(action => {
        action.isDisable = action.display_id !== buttonType || false;
        return action;
      });
    }
    return statusActionList;
  }

  isNullOrUndefined(prop: any) {
    return prop !== undefined && prop !== null;
  }
}
