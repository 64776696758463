/**
 * ・定義ファイル名
 *   マスタメンテナンス画面定義ファイル
 * ・概要
 *   マスタメンテナンス画面（社員マスタ）で使用する定義ファイル
 */

/* eslint-disable @typescript-eslint/camelcase */
export const EmployeeStatusList = {
  アカウント未発行: {
    編集する: 'employee_valid_account_not_issued_update',
    アカウント発行: 'employee_valid_account_not_issued_change'
  },
  アカウント発行済: {
    編集する: 'employee_valid_account_issued_update',
    アカウント無効: 'employee_valid_account_invalid_change'
  },
  アカウント無効: {
    アカウント再発行: 'employee_valid_account_reissued'
  }
};

export const EmployeeListDefine = {
  display: 'employeeList',
  editPage: 'SettingEmployeeEdit',
  editPagePath: 'setting/employee/edit',
  searchFieldCols: '10% 10% 10% 10% 10% 10%',
  tableColumns: [
    { text: 'ステータス', value: 'Status', width: 110 },
    { text: '社員名', value: 'name', width: 130 },
    { text: 'E-mailアドレス（システムアカウント）', value: 'activated_email_address', width: 200 },
    { text: '部署', value: 'department_id', width: 200 },
    { text: '操作権限', value: 'operation_authority_name', width: 200 },
    { text: 'アカウント有効化日', value: 'account_activation_date', width: 140 },
    { text: 'アカウント無効化日', value: 'account_invalidation_date', width: 140 }
  ],
  tableSelect: [
    {
      name: 'new_u_id',
      alwaysDisplayed: true,
      options: [] as { [k: string]: string }[],
      placeholder: '変更なし',
      rules: 'is_uniq_account'
    }
  ],
  tableCheck: [{ name: 'is_work_active', alwaysDisplayed: true, value: false, isDisabled: true }],
  message: {
    dataTableText: '検索してください',
    pageDescription:
      '社員マスタの一括更新を行います。社員マスタに環境ユーザーを連携させることができます。\nまた、チェックBOXにチェックを入れることで、選択されたユーザとアカウントの紐づけを行い、\nシステムにログイン可能な状態にします',
    overwriteWarn: 'アカウントの切替が発生します',
    duplicateWarn: '重複した環境ユーザを登録しようとしています',
    notSearch: '社員検索してください',
    notUpdateRow: '更新対象レコードがありません',
    actionConf:
      '選択されたユーザとアカウントの紐づけを行い、システムにログイン可能な状態にします。',
    updateFail: '更新に失敗しました。',
    updateSuccess: '更新が成功しました。'
  },
  action: 'update',
  dialog: {
    processDialogTitle: '処理実行',
    errDialogTitle: 'エラー'
  },
  inputColumns: {
    activeCheckBox: 'is_work_active',
    activeCheckBoxDisable: 'is_work_active-disable',
    messageColumn: 'column_message',
    modifyUid: 'new_u_id'
  }
};

export const EmployeeDetailDefine = {
  regist: {
    registActionName: 'register',
    message: {
      registSuccess: '内容を登録しました',
      execAction: 'この内容でよろしければ実行ボタンを押してください'
    },
    dialogTitle: '処理実行'
  },
  edit: {
    initialActionName: 'employee_valid_update',
    updateActionName: 'employee_valid_update',
    message: {
      updateSuccess: '内容を更新しました',
      execAction: 'この内容でよろしければ実行ボタンを押してください',
      accountRegisterSuccess: 'アカウント発行を行いました。'
    },
    actionPanelLabel: '',
    dialogTitle: '処理実行'
  }
};

export const Employeefields = {
  activeAccount: {
    displayId: 'is_active',
    value: {
      active: '1',
      inactive: '2'
    }
  },
  userJoin: {
    displayId: 'u_id'
  }
};

export const EmployeeMasterDeleteStatusExcludeSearch = {
  id: 'Status', // Hexabase画面で入力したIDを指定
  search_value: ['employee_deleted'],
  exact_match: true, // 完全一致で検索
  not_match: true // search_valueに指定した値でないItemを検索(NOT条件)
};
