









































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import SingleText from '@/components/common/elements/SingleText.vue';
import Typography from '@/components/common/elements/Typography.vue';
import Button from '@/components/common/elements/Button.vue';

@Component({
  components: { SingleText, Typography, Button }
})
export default class TransitionDialog extends Vue {
  // ダイアログ開閉状態
  @Prop({ default: false })
  private dialog?: boolean;
  // ダイアログ内表示タイトル
  @Prop({ default: '' })
  private title!: string;
  // ダイアログ内表示テキスト
  @Prop({ default: '' })
  private bodyText!: string;
  // ダイアログのエリア外を押下した際にBOXを閉じるか
  @Prop({ default: true })
  private isPersistent?: boolean;
  // アクションを起こすボタン配列
  @Prop({ default: () => [] })
  private buttons?: Array<{ [k: string]: any }>;
  // ローディング
  @Prop({ default: true })
  private isLoading?: boolean;

  /**
   * 親コンポーネントに通知後、処理を分岐する
   * @return void
   */
  public branchOpratipon(button: { [k: string]: any }): void {
    this.confirmOpratipon(button);
    let param: { [k: string]: string } = {};

    switch (button.type) {
      case 'routing': {
        if (button.setting === undefined) {
          param = { path: button.route };
        } else {
          const settingObj = button.setting;
          param = { ...{ name: button.routeName }, ...settingObj };
        }
        this.$router.push(param);
        break;
      }
      case 'reload': {
        this.$router.go(0);
        break;
      }
      default: {
        break;
      }
    }
  }
  /**
   * 親コンポーネントに押下したか否か通知する
   * @return {name: button.name} - 押下された要素名
   */
  @Emit('confirmOpratipon')
  public confirmOpratipon(button: { [k: string]: string }): { [k: string]: string } {
    return { name: button.name };
  }
}
