/**
 * 社員マスタ用Form定義ファイル
 * 概要
 *  社員マスタ用のForm定義をまとめたファイル
 * 更新履歴
 *  2022/04/20 新規
 */
import { FieldChildrenProp } from '@/services/hexabase/types';

/* eslint-disable @typescript-eslint/camelcase */
export const EmployeeRules = [
  {
    id: 'id',
    rule: ['is_uniq_account']
  },
  {
    id: 'email',
    rule: ['email']
  },
  {
    id: 'u_id',
    rule: ['is_uniq_account']
  }
];

export const employeeMasterForm = [
  {
    display_id: 'display_employee_id',
    dataType: 'text',
    component: 'InputArea',
    show: false
  },
  {
    display_id: 'operation_authority_name',
    dataType: 'text',
    component: 'InputArea',
    show: false
  },
  {
    display_id: 'department_id',
    component: 'MasterSelectControl',
    dblookup: 'm_department',
    props: {
      leftSideInputName: 'department_id',
      rightSideInputName: 'name',
      masterId: 'id',
      dialogTitle: '部署選択',
      label: '部署',
      columns: [],
      body: [],
      isRightSideInputReadOnly: true,
      hideDataConditions: [
        {
          fieldId: 'status',
          value: ['削除済']
        }
      ]
    }
  },

  {
    display_id: 'email',
    props: {
      labelText: 'メールアドレス（社内）'
    }
  }
];

export const employeeGroupForm: Array<FieldChildrenProp> = [
  {
    //** インタフェース利用のために必要なプロパティ、画面では未使用 **//
    i_id: '',
    search: false,
    show_list: false,
    as_title: false,
    status: false,
    fieldIndex: 1,
    title_order: 1,
    full_text: false,
    unique: false,
    min_value: '',
    max_value: '',
    // ****** //
    field_id: 'operation_authority_name',
    name: 'operation_authority_name',
    display_id: 'operation_authority_name',
    dataType: 'select',
    component: 'SelectArea',
    props: {
      name: 'operation_authority_name',
      label: '操作権限',
      selectData: [],
      rules: '',
      isReadonly: true,
      isDisabled: true,
      value: ''
    },
    sizeX: 1,
    sizeY: 1,
    col: 1,
    row: 0,
    show: true
  }
];

export const employeeAccountRegisterForm = [
  {
    //** インタフェース利用のために必要なプロパティ、画面では未使用 **//
    search: false,
    show_list: false,
    as_title: false,
    status: false,
    fieldIndex: 1,
    title_order: 1,
    full_text: false,
    unique: false,
    min_value: '',
    max_value: '',
    // ****** //
    field_id: 'activated_email_address',
    name: 'E-mailアドレス（システムアカウント）',
    display_id: 'activated_email_address',
    dataType: 'text',
    component: 'InputArea',
    props: {
      labelClass: 'mb-2',
      name: 'activated_email_address',
      labelName: 'E-mailアドレス（システムアカウント）',
      labelText: 'E-mailアドレス（システムアカウント）',
      isReadonly: true,
      isDisabled: true,
      rules: '',
      value: ''
    },
    sizeX: 1,
    sizeY: 1,
    col: 0,
    row: 0,
    show: true
  }
];

export const employeeAccountRegisterDialogForm = [
  {
    //** インタフェース利用のために必要なプロパティ、画面では未使用 **//
    search: false,
    show_list: false,
    as_title: false,
    status: false,
    fieldIndex: 1,
    title_order: 1,
    full_text: false,
    unique: false,
    min_value: '',
    max_value: '',
    // ****** //
    field_id: 'activated_email_address',
    name: 'E-mailアドレス（システムアカウント）',
    display_id: 'activated_email_address',
    dataType: 'text',
    component: 'TextArea',
    props: {
      labelClass: 'mb-2',
      name: 'activated_email_address',
      labelName: 'E-mailアドレス（システムアカウント）',
      labelText: 'E-mailアドレス（システムアカウント）',
      isReadonly: true,
      isDisabled: true,
      rules: '',
      value: ''
    },
    sizeX: 1,
    sizeY: 1,
    col: 0,
    row: 0,
    show: true
  },
  {
    //** インタフェース利用のために必要なプロパティ、画面では未使用 **//
    search: false,
    show_list: false,
    as_title: false,
    status: false,
    fieldIndex: 1,
    title_order: 1,
    full_text: false,
    unique: false,
    min_value: '',
    max_value: '',
    // ****** //
    field_id: 'password',
    name: 'password',
    display_id: 'password',
    dataType: 'text',
    component: 'InputArea',
    props: {
      labelClass: 'mb-2',
      labelName: '仮パスワード',
      labelText: '仮パスワード',
      name: 'password',
      isReadonly: false,
      isDisabled: false,
      rules: 'required',
      value: ''
    },
    sizeX: 1,
    sizeY: 1,
    col: 0,
    row: 1,
    show: true
  },
  {
    //** インタフェース利用のために必要なプロパティ、画面では未使用 **//
    search: false,
    show_list: false,
    as_title: false,
    status: false,
    fieldIndex: 1,
    title_order: 1,
    full_text: false,
    unique: false,
    min_value: '',
    max_value: '',
    // ****** //
    field_id: 'operation_authority_name',
    name: 'operation_authority_name',
    display_id: 'operation_authority_name',
    dataType: 'text',
    component: 'TextArea',
    props: {
      labelClass: 'mb-2',
      labelName: '操作権限',
      labelText: '操作権限',
      name: 'operation_authority_name',
      isReadonly: true,
      isDisabled: true,
      rules: '',
      value: ''
    },
    sizeX: 1,
    sizeY: 1,
    col: 0,
    row: 3,
    show: true
  }
];

/* eslint-disable-next-line @typescript-eslint/no-namespace */
namespace searchForm {
  export const EMPLOYEE_MAINTENANCE = [
    {
      display_id: 'Status', //対象となるdisplay_id
      component: 'SelectArea', //任意で使用するコンポーネント
      col: 0, //Gridで表示する開始コラム数(横)
      props: {
        label: 'ステータス',
        name: 'Status',
        placeholder: '全ステータス'
      },
      row: 1, //Gridで表示する行数(縦)
      sizeX: 3, //コンポーネントが占有する横幅
      sizeY: 1
    },
    {
      display_id: 'name',
      col: 0,
      row: 2,
      sizeX: 3,
      sizeY: 1,
      show: true
    },
    {
      display_id: 'name_kana',
      col: 3,
      row: 2,
      sizeX: 3,
      sizeY: 1,
      show: true
    },
    {
      display_id: 'tel',
      col: 0,
      row: 4,
      sizeX: 3,
      sizeY: 1,
      show: true
    },
    {
      display_id: 'activated_email_address',
      col: 3,
      row: 4,
      sizeX: 3,
      sizeY: 1,
      show: true,
      props: {
        labelText: 'E-mailアドレス（システムアカウント）'
      }
    },
    {
      dataType: 'datetime',
      component: 'DatePickerRangeArea',
      display_id: 'account_activation_date',
      props: {
        format: 'yyyy/MM/dd',
        nameFrom: 'account_activation_date',
        nameTo: 'account_activation_date',
        valueFrom: '',
        valueTo: ''
      },
      col: 0,
      row: 5,
      sizeX: 3,
      sizeY: 1,
      show: true
    },
    {
      dataType: 'datetime',
      component: 'DatePickerRangeArea',
      display_id: 'account_invalidation_date',
      props: {
        format: 'yyyy/MM/dd',
        nameFrom: 'account_invalidation_date',
        nameTo: 'account_invalidation_date',
        valueFrom: '',
        valueTo: ''
      },
      col: 3,
      row: 5,
      sizeX: 3,
      sizeY: 1,
      show: true
    }
  ];
}
export const searchFormEmployeeList = searchForm.EMPLOYEE_MAINTENANCE;
